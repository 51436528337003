import { render, staticRenderFns } from "./MediaCategory.vue?vue&type=template&id=bde9fce2&scoped=true&"
import script from "./MediaCategory.vue?vue&type=script&lang=js&"
export * from "./MediaCategory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bde9fce2",
  null
  
)

export default component.exports