<template>
     <responsive-data-table ref="table" :list-url="listUrl" :show-search="true">
        <template #buttons>
            <div class="fl-te-c">
                <h4 class="font-inter-semi-bold fs-5">Property Sub Category</h4>
                <btn color="success" icon="fa fa-plus" size="sm" @click="$refs.addModal.show()" text="Add"/>
            </div>
        </template>
         <template #data="{tableData}">
            <div v-for="(i, k) in tableData" :key="k" class="mt-2">
                <div class="b-1">
                    <div class="row font-inter-medium c-line-bottom p-0 m-0">
                        <div class=" col-12 col-lg-6 br-1 br-sm-0">
                            <div class="py-2">
                                <div class="pos-r fs--2 fl-x shrink-0">
                                    <span class="text-2 mr-2">ID :</span>
                                    {{ i.id }}
                                </div>
                            </div>
                        </div>
                        <div class=" col-12 col-lg-6 br-1 br-sm-0 bt-1">
                            <div class="py-2">
                                <div class="pos-r fs--2 fl-x ">
                                    <span class="text-2 mr-2 shrink-0">Property Type :</span>
                                    {{ i.property_type_name }}
                                </div>
                            </div>
                        </div>
                        <div class=" col-12 col-lg-12 br-1 br-sm-0 bt-1">
                            <div class="py-2">
                                <div class="pos-r fs--2 fl-x ">
                                    <span class="text-2 mr-2 shrink-0">Property Sub Category :</span>
                                    {{ i.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row fs--1 m-0">
                        <div class="bl-sm-0 bt-1 col-12 fl-y-bc">
                            <div class="btn-group fl-x fl-j-s py-3">
                                <edit-btn icon="fa fa-pencil" text="Edit" size="xs"
                                          class="border-gray-hover p-2 text-5"
                                          @click="setEdit(i)"></edit-btn>
                                <delete-btn icon="fa fa-trash" text="Delete" color="danger" size="xs"
                                            class="border-gray-hover p-2 text-5"
                                            @click="setDelete(i)"></delete-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
          <template #footer>
            <!--    Modals    -->
             <modal title="Add Property Sub Category" ref="addModal" width="30r" header-color="primary" no-close-on-backdrop>
                    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
                        <validated-input label="Property Sub Category*"
                                         v-model="model.name"
                                         :rules="rules.sub_category"
                                         :disabled="loading"/>
                        <validated-ajax-vue-select :url="propertyTypeOptions"
                                                   label="Property Type*"
                                                   v-model="model.property_type"
                                                   :rules="rules.property_type"
                                                   :disabled="loading"/>
                        <btn text="Save" loading-text="Saving..." size="sm" :disabled="loading" :loading="loading"/>
                    </b-form>
                </modal>

                <modal title="Edit Property Sub Category" ref="editModal" width="30r" header-color="primary" no-close-on-backdrop>
                    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" :initial-data="editingItem">
                        <validated-input label="Property Sub Category*"
                                         v-model="model.name"
                                         :rules="rules.sub_category"
                                         :disabled="loading"/>
                        <validated-ajax-vue-select :url="propertyTypeOptions"
                                                   label="Property Type*"
                                                   v-model="model.property_type"
                                                   :rules="rules.property_type"
                                                   :disabled="loading"/>
                        <btn text="Update" loading-text="Updating..." size="sm" :disabled="loading" :loading="loading"/>
                    </b-form>
                </modal>

                <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteSuccess">
                    <p>You are about to delete the property sub category <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?</p>

                    <template #loading>
                        <loading-animation/>
                        Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                    </template>
                </delete-modal>
        </template>
     </responsive-data-table>
</template>

<script>
import urls from '@/data/cms-urls';

export default {
    name : 'PropertySubCategory',
    data () {
        return {
            // API urls
            listUrl             : urls.masterData.PropertySubCategory.list,
            addUrl              : urls.masterData.PropertySubCategory.addEdit,
            deleteUrl           : urls.masterData.PropertySubCategory.delete,
            propertyTypeOptions : urls.masterData.PropertySubCategory.propertyTypeOptions,

            // Form variables
            editingItem  : null,
            deletingItem : null,

            // Validation variables
            rules : {
                property_type : {
                    required : true
                },
                sub_category : {
                    required : true
                }
            }
        };
    },
    methods : {
        // Form related functions
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },
        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },
        formSuccess () {
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            this.refreshData();
            this.editingItem = null;
        },
        async deleteSuccess () {
            try {
                this.$refs.deleteModal.close();
                this.refreshData();
                // this.$emit('refreshStates');
                // this.$emit('refreshDistrict');
                this.$notify('Deleted Successfully', 'Success', { type : 'success' });
                this.deletingItem = null;
            } catch (error) {
                this.$notify('Failed to delete', 'Error', { type : 'danger' });
            }
        },
        refreshData () {
            this.$refs.table.refreshData();
        }
    }
};
</script>

<style scoped>

</style>
