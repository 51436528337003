<template>
    <div class="row py-5">

        <div class="col-md-6 mb-5">
            <country @refreshStates="refreshStates" @refreshDistrict="refreshDistrict" ref="country" class="bs-4"/>
        </div>

        <div class="col-md-6 mb-5">
            <state ref="state" @refresh="refreshDistrict" class="bs-4"/>
        </div>

        <div class="col-md-6 mb-5">
            <district ref="district" class="bs-4"/>
        </div>

        <div class="col-md-6 mb-5">
            <property-types @refresh="refreshPropertySubCategory" ref="propertyType" class="bs-4"/>
        </div>

        <div class="col-md-6 mb-5">
            <property-sub-category ref="propertySubCategory" class="bs-4"/>
        </div>

        <div class="col-md-6 mb-5">
            <media-category class="bs-4"/>
        </div>

        <div class="col-md-12 mb-5">
            <tutorial-page/>
        </div>

        <div class="col-md-12 mb-5">
            <user-manuel/>
        </div>

        <div class="col-md-12 mb-5">
            <contact-ventura/>
        </div>

    </div>
</template>

<script>
import State from './State';
import District from './District';
import PropertyTypes from './PropertyTypes';
import PropertySubCategory from './PropertySubCategory';
import MediaCategory from './MediaCategory';
import Country from './Country';
import UserManuel from './UserManual';
import TutorialPage from './TutorialPage';
import ContactVentura from './ContactVentura';

export default {
    name : 'MasterData',

    components : {
        ContactVentura,
        TutorialPage,
        UserManuel,
        MediaCategory,
        PropertySubCategory,
        State,
        District,
        PropertyTypes,
        Country

    },

    methods : {
        refreshStates () {
            this.$refs.state.refreshData();
        },

        refreshDistrict () {
            this.$refs.district.refreshData();
        },

        refreshPropertySubCategory () {
            this.$refs.propertySubCategory.refresh();
        }
    }
};
</script>
